// Generated by Framer (b0f2619)

import { addFonts, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WEJMfkOlT"];

const serializationHash = "framer-G80p7"

const variantClassNames = {WEJMfkOlT: "framer-v-1rmps50"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WEJMfkOlT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={{webPageId: "augiA20Il"}}><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1rmps50", className, classNames)} framer-yhgm66`} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"WEJMfkOlT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "RlM7U2F0b3NoaS1ib2xk", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "168.82357688367594px", "--framer-font-weight": "700", "--framer-letter-spacing": "-9px", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-gdpscs, var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255)))"}}>newsplate</motion.h1></React.Fragment>} className={"framer-ldm0qt"} data-framer-name={"Logo"} fonts={["FS;Satoshi-bold"]} layoutDependency={layoutDependency} layoutId={"rAGiyHR92"} style={{"--extracted-gdpscs": "var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255))"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} viewBox={"0 0 718 169"} withExternalLayout/></motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-G80p7[data-border=\"true\"]::after, .framer-G80p7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G80p7.framer-yhgm66, .framer-G80p7 .framer-yhgm66 { display: block; }", ".framer-G80p7.framer-1rmps50 { height: 287px; overflow: hidden; position: relative; text-decoration: none; width: 1220px; }", ".framer-G80p7 .framer-ldm0qt { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: 1220px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 287
 * @framerIntrinsicWidth 1220
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerhbxUoKtUO: React.ComponentType<Props> = withCSS(Component, css, "framer-G80p7") as typeof Component;
export default FramerhbxUoKtUO;

FramerhbxUoKtUO.displayName = "Header/Logo";

FramerhbxUoKtUO.defaultProps = {height: 287, width: 1220};

addFonts(FramerhbxUoKtUO, [{family: "Satoshi", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/LAFFD4SDUCDVQEXFPDC7C53EQ4ZELWQI/PXCT3G6LO6ICM5I3NTYENYPWJAECAWDD/GHM6WVH6MILNYOOCXHXB5GTSGNTMGXZR.woff2", weight: "700"}])